<template>
  <!-- 全屏容器 -->
  <v-scale-screen width="1920" height="1080" fullScreen>
    <div class="container">
      <Time style="position: absolute;top: 40px;left: 150px;"></Time>
      <weather  style="position: absolute;top: 50px;right: 150px;z-index: 99;"></weather>
      <!-- 标题栏部分 -->
      <AiHeader
        ref="mychild"
        :dianzhang="true"
        :AiHeaderQuery="AiHeaderQuery"
      ></AiHeader>
      <div class="storageContainer">
        <div class="left">
          <div>
            <!-- 门店信息 -->
            <div
              data-title="门店信息"
              class="card columns"
              style="margin-bottom: 30px"
            >
              <div class="logo" style="display: flex; align-items: center">
                <img
                  :src="shop_img"
                  style="
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-radius: 20px;
                    opacity: 0.8;
                  "
                />
                <div
                  style="
                    color: white;
                    padding-left: 20px;
                    margin-top: 5px;
                    z-index: 999;
                    font-weight: bolder;
                    background: rgba(52, 152, 219, 0.3) !important;
                    width: 100%;
                    height: 80%;
                  "
                >
                  <div
                    class="phone"
                    style="
                      margin: 5px 0;
                      font-size: 24px;
                    "
                  >
                    <span>{{ shop_name }}</span>
                  </div>
                  <div class="phone" style="margin: 5px 0">
                    <span>负 责 人 ：</span>
                    <span>{{ shop_owner }}</span>
                  </div>
                  <div class="phone" style="margin-bottom: 10px">
                    <span>联系电话：</span>
                    <span>{{ shop_phone }}</span>
                  </div>
                  <div class="phone" style="margin-bottom: 10px">
                    <span>餐厅面积：</span>
                    <span>{{ area }}</span>
                  </div>
                  <div class="phone" style="margin-bottom: 10px">
                    <span>就餐人数：</span>
                    <span>{{ users_amount }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- 任务统计 -->
            <div class="card columns total" data-title="任务统计">
              <Echarts
                v-if="completedPercent != null"
                :percent="completedPercent"
                :num="completedNum"
                type="已完成"
                style="
                  width: 40%;
                  height: 50%;
                  position: absolute;
                  top: 50px;
                  left: -20px;
                "
              ></Echarts>
              <Echarts
                v-if="todpercent != null"
                :percent="todpercent"
                :num="todNum"
                type="待完成"
                style="
                  width: 40%;
                  height: 50%;
                  position: absolute;
                  top: 340px;
                  left: -20px;
                "
              ></Echarts>
              <div
                style="                 
                  position: absolute;
                  bottom: 30px;
                  right: 50px;
                  font-weight: bolder;
                  text-align: center;color: rgba(189, 195, 199, 1); font-size: 16px
                "
              >
                <div>
                  任务总数：{{ total_tasks }}个
                </div>
              </div>
              <Echarts
                v-if="overtimePercent != null"
                :percent="overtimePercent"
                :num="overtimeNum"
                type="已超时"
                style="
                  width: 40%;
                  height: 50%;
                  position: absolute;
                  top: 342px;
                  left: 190px;
                "
              ></Echarts>
              <Echarts
                v-if="soon_end_percentage != null"
                :percent="soon_end_percentage"
                :num="soon_end_num"
                type="即将超时"
                style="
                  width: 40%;
                  height: 50%;
                  position: absolute;
                  top: 52px;
                  left: 190px;
                "
              ></Echarts>
            </div>
          </div>
        </div>
        <div class="right">
          <dv-border-box-13
            style="padding: 15px 0px 0px 5px; height: 920px;width: 830px;"
            class="yiwanchengTable"
          >
            <div
              style="
                background-color: rgba(0, 0, 0, 0.4);
                width: 98%;
                border-radius: 5px;
              "
            >
              <span
                style="
                  color: rgb(42, 165, 21);
                  font-weight: bolder;
                  font-size: 36px;
                  margin-left: 10px;
                  display: block;
                "
                >已完成任务</span
              >
            </div>
            <div class="taskPic">
              <div v-if="!secondContent.length > 0">
                <img
                  style="height: 430px; width: 100%"
                  src="./../assets/image/NoImg.png"
                  alt=""
                />
              </div>
              <el-carousel
                class="right-ul"
                height="430px"
                style="border-radius: 20px"
                indicator-position="none"
                v-else
              >
                <el-carousel-item
                  v-for="(item, index) in secondContentSrc"
                  :key="index"
                >
                  <div>
                    <el-image
                      class="img"
                      :src="item.photoUrl"
                      :preview-src-list="item.photoUrlList"
                    ></el-image>
                    <div class="text">
                      <div class="oneTitle">
                        <i class="el-icon-paperclip"></i>
                        {{ item.title }}:
                      </div>
                      <div class="twoTitle">
                        {{ item.describle }}
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <el-table
              :data="tableData"
              ref="rolltable4"
              @mouseenter.native="stopScroll"
              @mouseleave.native="startScroll"
              height="410"
              style="
                width: 810px;
                height: 100%;
                margin-top: 5px;
                margin-left: 5px;
              "
              :header-cell-style="{
                fontSize: '22px',
                color: 'rgb(42, 165, 21)',
                background: '#0a3370',
                fontWeight: '700',
              }"
            >
              <el-table-column prop="group_name" label="任务名称" width="">
                <template slot-scope="scope">
                  <slot>
                    <div style="display: flex; align-items: center">
                      <i class="el-icon-paperclip"></i>
                      <span>{{ scope.row.group_name }}</span>
                    </div>
                  </slot>
                </template>
              </el-table-column>
              <el-table-column prop="time" label="任务时间" width="250">
              </el-table-column>
              <div
                slot="empty"
                class="empty"
                style="display: flex; align-items: center"
              >
                <img
                  src="./../assets/image/NoImg.png"
                  style="width: 96%; height: 150px"
                />
              </div>
            </el-table>
          </dv-border-box-13>
          <div class="bottom-right">
            <div class="daiwancheng">
              <dv-border-box-13 style="padding: 20px 15px">
                <div
                  style="
                    background-color: rgba(0, 0, 0, 0.4);
                    width: 100%;
                    border-radius: 5px;
                  "
                >
                  <span
                    style="color: white; font-weight: bolder; font-size: 36px"
                    >待完成任务</span
                  >
                </div>
                <el-table
                  :data="daiwanchengList"
                  ref="rolltable"
                  @mouseenter.native="stopScroll"
                  @mouseleave.native="startScroll"
                  height="210"
                  style="width: 570px; height: 100%; margin-top: 12px"
                  :header-cell-style="{
                    fontSize: '22px',
                    color: '#fff',
                    background: '#0a3370',
                    fontWeight: '700',
                  }"
                >
                  <el-table-column prop="group_name" label="任务名称" width="">
                    <template slot-scope="scope">
                      <slot>
                        <div style="display: flex; align-items: center">
                          <i class="el-icon-paperclip"></i>
                          <span>{{ scope.row.group_name }}</span>
                        </div>
                      </slot>
                    </template>
                  </el-table-column>
                  <el-table-column prop="time" label="任务时间" width="250">
                  </el-table-column>
                  <div
                    slot="empty"
                    class="empty"
                    style="display: flex; align-items: center"
                  >
                    <img
                      src="./../assets/image/NoImg.png"
                      style="width: 96%; height: 150px"
                    />
                  </div>
                </el-table>
              </dv-border-box-13>
            </div>
            <div class="jijiang" style="margin: 10px 0">
              <dv-border-box-13 style="padding: 20px 15px" class="jijiangTable">
                <div
                  style="
                    background-color: rgba(0, 0, 0, 0.4);
                    width: 100%;
                    border-radius: 5px;
                  "
                >
                  <span
                    style="
                      color: rgb(240, 255, 0);
                      font-weight: bolder;
                      font-size: 36px;
                    "
                    >即将超时任务</span
                  >
                </div>
                <div class="loader2">
                  <!-- <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    height="24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    class="w-6 h-6 text-gray-800 dark:text-white"
                  >
                    <path
                      d="M12 5.365V3m0 2.365a5.338 5.338 0 0 1 5.133 5.368v1.8c0 2.386 1.867 2.982 1.867 4.175 0 .593 0 1.292-.538 1.292H5.538C5 18 5 17.301 5 16.708c0-1.193 1.867-1.789 1.867-4.175v-1.8A5.338 5.338 0 0 1 12 5.365ZM8.733 18c.094.852.306 1.54.944 2.112a3.48 3.48 0 0 0 4.646 0c.638-.572 1.236-1.26 1.33-2.112h-6.92Z"
                      stroke-width="2"
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke="currentColor"
                    ></path>
                  </svg> -->
                  <div class="point"></div>
                </div>
                <el-table
                  :data="jijiangList"
                  ref="rolltable2"
                  @mouseenter.native="stopScroll"
                  @mouseleave.native="startScroll"
                  height="210"
                  style="width: 570px; height: 100%; margin-top: 12px"
                  :header-cell-style="{
                    fontSize: '22px',
                    color: 'rgb(240, 255, 0);',
                    background: '#0a3370',
                    fontWeight: '700',
                  }"
                >
                  <el-table-column prop="group_name" label="任务名称" width="">
                    <template slot-scope="scope">
                      <slot>
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: left;
                          "
                        >
                          <img
                            src="./../assets/image/sucai4.gif"
                            style="width: 60px; height: 30px"
                          />
                          <span>{{ scope.row.group_name }}</span>
                        </div>
                      </slot>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="left_minute"
                    label="剩余时间"
                    width="200"
                  >
                    <template slot-scope="scope">
                      <slot>
                        <span>{{ scope.row.left_minute }}（分钟）</span>
                      </slot>
                    </template>
                  </el-table-column>
                  <div
                    slot="empty"
                    class="empty"
                    style="display: flex; align-items: center"
                  >
                    <img
                      src="./../assets/image/NoImg.png"
                      style="width: 96%; height: 150px"
                    />
                  </div>
                </el-table>
              </dv-border-box-13>
            </div>
            <div class="yichaoshi">
              <dv-border-box-13
                style="padding: 20px 15px; "
                class="yichaoshiTable"
              >
                <div
                  style="
                    background-color: rgba(0, 0, 0, 0.4);
                    width: 100%;
                    border-radius: 5px;
                  "
                >
                  <span
                    style="
                      color: rgb(217, 30, 24);
                      font-weight: bolder;
                      font-size: 36px;
                    "
                    >已超时任务</span
                  >
                </div>
                <el-table
                  :data="yichaoshiList"
                  ref="rolltable3"
                  @mouseenter.native="stopScroll"
                  @mouseleave.native="startScroll"
                  height="200"
                  style="width: 570px; height: 100%; margin-top: 12px"
                  :header-cell-style="{
                    fontSize: '22px',
                    color: 'rgb(217, 30, 24)',
                    background: '#0a3370',
                    fontWeight: '700',
                  }"
                >
                  <el-table-column prop="group_name" label="任务名称" width="">
                    <template slot-scope="scope">
                      <slot>
                        <div style="display: flex; align-items: center">
                          <i class="el-icon-paperclip"></i>
                          <span>{{ scope.row.group_name }}</span>
                        </div>
                      </slot>
                    </template>
                  </el-table-column>
                  <el-table-column prop="time" label="任务时间" width="250">
                  </el-table-column>
                  <div
                    slot="empty"
                    class="empty"
                    style="display: flex; align-items: center"
                  >
                    <img
                      src="./../assets/image/NoImg.png"
                      style="width: 96%; height: 150px"
                    />
                  </div>
                </el-table>
              </dv-border-box-13>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-scale-screen>
</template>
<script>
import AiHeader from "../components/aiHeader.vue";
import Chart from "../components/chart.vue";
import Echarts from "../components/echarts";
import Time from "../components/time.vue";
import weather from "..//components/weather.vue";
import { baseImgUrl } from "../utils/request";
import {
  getTaskRecordV2,
  getTaskRecordByStoreId,
  getTaskDoRate,
} from "../api/api";
// 引入混入
import { myMixin } from "../common/common.js";

export default {
  data() {
    return {
      secondContent: [],
      secondContentSrc: [],
      daiwanchengList: [], //待完成列表
      jijiangList: [], //即将超时列表
      yichaoshiList: [], //已超时列表
      tableData: [], //已完成任务列表
      completedPercent: null, //完成率
      completedNum: null, //完成数量
      todpercent: null, //待完成完成率
      todNum: null, //待完成数量
      overtimePercent: null, //超时完成率
      overtimeNum: null, //超时数量
      soon_end_percentage: null, //即将超时完成率
      soon_end_num: null, //即将超时数量
      total_tasks: null, //总任务数
      timer: null,
      AiHeaderQuery: false,
    };
  },
  mixins: [myMixin],
  computed: {
    searchDay() {
      const dateTimeString = new Date();
      const date = new Date(dateTimeString);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // 月份是从0开始计数的，需要加1
      const day = date.getDate();
      const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
      return formattedDate;
    },
  },
  methods: {
    // 获取店长助手任务清单列表
    async getTaskRecordV2Date() {
      const res = await getTaskRecordV2({
        store_id: localStorage.getItem("store_id"),
        search_day: this.searchDay,
        // search_day: "2024-11-06",
      });
      /// 假设res.data.data.taskList是您的数组
      this.taskList = res.data.data.taskList;

      if (this.taskList) {
        // 使用map方法对数组中的每个对象进行处理
        this.taskList.forEach((item) => {
          // 判断time字段是否存在并且长度大于等于6
          if (item.time && item.time.length >= 6) {
            // 截取第6位到最后的字符串并直接赋值给原数组
            item.time = item.time.substring(11);
          }
        });
        // 使用forEach方法遍历taskList数组中的每个对象
        this.taskList.forEach((item) => {
          // 遍历每个对象的tasks数组
          if (item?.task) {
            item?.task.forEach((t) => {
              // 将arr_score_content字符串数组转换为对象数组，并添加checked字段
              t.arr_score_content = t.arr_score_content.map((score) => {
                return { value: score, checked: true };
              });
            });
          }
        });
        this.taskList.forEach((item) => {
          if (item.new_task_status === "soon_end") {
            this.jijiangList.push(item);
          }
          if (item.new_task_status === "overtime") {
            this.yichaoshiList.push(item);
          }
          if (item.new_task_status === "doing") {
            this.daiwanchengList.push(item);
          }
          if (item.new_task_status === "done") {
            this.tableData.push(item);
          }
        });
      }
    },
    //获取轮播里的数据
    async getTaskRecordByStoreId() {
      const res = await getTaskRecordByStoreId({
        store_id: localStorage.getItem("store_id"),
        search_day: this.searchDay,
      });
      if (res.data.code === 0) {
        this.secondContent = [];
      } else {
        Object.values(res?.data.data)[0]?.task.forEach((item) => {
          this.secondContent.push(item);
          item.arr_photo_url.forEach((photoUrl) => {
            let obj = {
              photoUrl: photoUrl,
              describle: item.describle,
              title: item.assess_item_name,
            };
            this.secondContentSrc.push(obj);

            // 创建一个对象来存储相同describle的photoUrl
            let photoUrlMap = [];

            // 遍历secondContentSrc数组，将相同describle的photoUrl存储到photoUrlMap中
            this.secondContentSrc.forEach((item) => {
              photoUrlMap.push(item.photoUrl);
            });

            // 往secondContentSrc数组中的每个item添加photoUrlList数组
            this.secondContentSrc.forEach((item) => {
              item.photoUrlList = photoUrlMap;
            });
          });
        });
      }
    },
    // 获取店长助手完成率
    async getTaskDoRateData() {
      const res = await getTaskDoRate({
        store_id: localStorage.getItem("store_id"),
      });
      this.completedPercent = res.data.data.day.completion_percentage;
      this.completedNum = res.data.data.day.completed_tasks;
      this.todpercent = res.data.data.day.todo_percentage;
      this.todNum = res.data.data.day.todo_tasks;
      this.overtimePercent = res.data.data.day.overtime_percentage;
      this.overtimeNum = res.data.data.day.overtime_tasks;
      this.soon_end_percentage = res.data.data.day.soon_end_percentage;
      this.soon_end_num = res.data.data.day.soon_end;
      this.total_tasks = res.data.data.day.total_tasks;
    },
  },
  components: {
    AiHeader,
    Echarts,
    Chart,
    Time,
    weather
  },
  created() {
    this.getTaskRecordByStoreId();
    this.getTaskRecordV2Date();
    this.getTaskDoRateData();
  },
  mounted() {
    document.title = "优食安数字食堂云平台";
    if (this.$route.query.from === "aiHeader") {
      this.AiHeaderQuery = true;
    }
    this.timer = setInterval(() => {
      this.secondContent = []
      this.secondContentSrc = []
      this.daiwanchengList = []; //待完成列表
      this.jijiangList = []; //即将超时列表
      this.yichaoshiList = []; //已超时列表
      this.tableData = []; //已完成任务列表
      this.completedPercent = null; //完成率
      this.completedNum = null; //完成数量
      this.todpercent = null; //待完成完成率
      this.todNum = null; //待完成数量
      this.overtimePercent = null; //超时完成率
      this.overtimeNum = null; //超时数量
      this.soon_end_percentage = null; //即将超时完成率
      this.soon_end_num = null; //即将超时数量
      this.total_tasks = null; //总任务数

      this.getTaskRecordByStoreId();
      this.getTaskRecordV2Date();
      this.getTaskDoRateData();
    }, 180000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="scss" scoped>
.carouselBox {
  margin: 0 auto;
  width: 100%;
  height: 230px;
  .carousel {
    width: 100%;
    height: 420px;
    .el-car-item {
      width: 100%;
      height: 420px;
      display: flex;
      .divSrc {
        width: 323px;
        height: 230px;
        background: #fff;
        margin-right: 46px;

        .img {
          width: 323px;
          height: 230px;
        }
        .title {
          width: 90%;
          height: 80px;
          line-height: 80px;
          margin: 0 auto;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          color: #222222;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    // ::v-deep .el-carousel__arrow {
    //   background: #99a9bf !important;
    //   display: block !important;
    //   margin-top: 65px;
    // }
  }
  .el-car-item {
    width: 100%;
    display: flex;

    .img {
      width: 284px;
      height: 184px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}

.container {
  height: 100%;
  width: 100%;
  // background: url("../assets/image/bg.577ccc4c.jpg");
  background: linear-gradient(170deg, #000a65 0%, #020924 100%);
  // background-color: #cdd1d3
}

/* 中间 */
.storageContainer {
  min-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 40px 20px 20px 20px;
  height: calc(100% - 160px);
  .left {
    width: 420px;
    height: 100%;
    display: flex;
    .columns {
      font-size: 18px;
      height: 21%;
      width: 410px;
      position: relative;
      border-radius: 20px;
      padding: 25px 5px 5px 10px;
      border: 2px solid white;
      transition: all 0.4s;
    }
    .total {
      display: flex;
      flex-wrap: wrap;
      height: 66%;
      position: relative;
    }

    .columns:hover {
      box-shadow: 4px 4px 0 1px rgba(255, 255, 255);
    }
  }
  .right {
    width: calc(100% - 440px);
    display: flex;
    justify-content: space-between;

    .taskPic {
      padding-top: 5px;
      padding-left: 5px;
      padding-right: 5px;
      .img {
        height: 560px;
        width: 99%;
        border-radius: 20px;
      }
      .text {
        width: 99%;
        position: absolute;
        bottom: 30px;
        color: white;
        font-weight: bolder;
        border-radius: 5px;
        background-color: rgba(39, 150, 28, 0.3);
        .oneTitle {
          text-align: left;
          padding: 20px 30px 10px 30px;
          font-size: 24px;
        }
        .twoTitle {
          padding: 10px 30px 20px 30px;
          font-size: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    .bottom-right {
      padding: 5px 20px;
      width: 570px;
      .jijiang {
        .loader2 {
          position: absolute;
          right: 2px;
          top: 2px;
        }

        .point {
          position: absolute;
          bottom: -30px;
          right: 20px;
          width: 12px;
          height: 12px;
          background-color: rgb(240, 255, 0);
          border-radius: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .point::before {
          content: "";
          position: absolute;
          width: 1px;
          height: 1px;
          background-color: rgb(240, 255, 0);
          border-radius: 25px;
          animation: loop2 1s 0s infinite;
        }

        @keyframes loop2 {
          0% {
            background-color: rgb(240, 255, 0);
            width: 10px;
            height: 10px;
          }
          100% {
            background-color: rgba(0, 255, 0, 0);
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }
  /* 添加标题样式 */
  .columns:before {
    content: attr(data-title);
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #25a6f3;
    color: #fff;
    font-size: 20px;
    font-weight: bolder;
    padding: 0px 30px;
    border-radius: 5px;
    border-bottom: 10px solid #25a6f3;
    padding-top: 5px;
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 10% 100%);
    z-index: 99;
  }

  .bottom:before,
  .top:before {
    padding-top: 10px;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table .warning-row {
  background: oldlace;
}
::v-deep .el-table__empty-block {
  background-color: rgb(2, 19, 49);
}
::v-deep .el-table__body-wrapper {
  background-color: rgb(2, 19, 49);
}

::v-deep .el-table .success-row {
  background: #f0f9eb;
}
::v-deep .el-carousel--horizontal {
  width: 820px !important;
}
// 已完成表格内背景颜色
.yiwanchengTable ::v-deep .el-table th,
.yiwanchengTable ::v-deep .el-table tr,
.yiwanchengTable ::v-deep .el-table td {
  background-color: rgb(2, 19, 49); // 背景透明
  border-bottom: 1px dashed rgba(42, 165, 21, 0.4);
  color: rgb(42, 165, 21);
  font-size: 22px;
  text-align: center;
  height: 5px;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: Normal;
}
// 即将超时表格内背景颜色
.jijiangTable ::v-deep .el-table th,
.jijiangTable ::v-deep .el-table tr,
.jijiangTable ::v-deep .el-table td {
  background-color: rgb(2, 19, 49); // 背景透明
  border-bottom: 1px dashed rgba(243, 156, 18, 0.4);
  color: rgb(240, 255, 0);
  font-size: 22px;
  text-align: center;
  height: 5px;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: Normal;
}
// 已超时表格内背景颜色
.yichaoshiTable ::v-deep .el-table th,
.yichaoshiTable ::v-deep .el-table tr,
.yichaoshiTable ::v-deep .el-table td {
  background-color: rgb(2, 19, 49); // 背景透明
  border-bottom: 1px dashed #e74d3c59;
  color: rgb(242, 38, 19);
  font-size: 22px;
  text-align: center;
  height: 5px;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: Normal;
}
// 待完成表格内背景颜色
.daiwancheng ::v-deep .el-table th,
.daiwancheng ::v-deep .el-table tr,
.daiwancheng ::v-deep .el-table td {
  background-color: rgb(2, 19, 49); // 背景透明
  border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
  color: white;
  font-size: 22px;
  text-align: center;
  height: 5px;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: Normal;
}
// 去掉最下面的那一条线
::v-deep .el-table::before {
  height: 0px;
}
// 修改表头样式-加边框
::v-deep .el-table__header-wrapper {
  border: solid 2px #063570;
  box-sizing: border-box;
}

// 修改高亮当前行颜色
::v-deep .el-table tbody tr:hover > td {
  background: #063570 !important;
}
// 滚动条样式
::v-deep .container .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px !important;
}

::v-deep .container .el-table__body-wrapper::-webkit-scrollbar-track-piece {
  background: rgb(1, 22, 55) !important;
}

::v-deep .container .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background: #99a9bf;
  border-radius: 20px;
}
::v-deep .el-table {
  th.gutter,
  colgroup.gutter {
    width: 4px !important; //此处的宽度值，对应你自定义滚动条的宽度即可
  }
}
::v-deep .el-table {
  th.gutter,
  colgroup.gutter {
    width: 4px !important; //此处的宽度值，对应你自定义滚动条的宽度即可
  }
}
// 关键css代码
::v-deep .el-table__header colgroup col[name="gutter"] {
  display: table-cell !important;
}
::v-deep .el-table__body {
  width: 101% !important;
}
</style>
