<template>
  <div>
    <el-carousel indicator-position="none" :interval="8000">
      <el-carousel-item
        v-for="(item, index) in listContainer"
        :key="index"
        style="
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          align-content: space-around;
        "
      >
        <div
          style="width: 48%; border-right: 1px solid #6e7a8b"
          v-for="i in item"
        >
          <div style="display: flex; margin: 10px 0 0 20px">
            <img
              :src="i.food_images"
              style="
                width: 180px;
                height: 120px;
                border-radius: 10px;
                margin-right: 20px;
              "
            />
            <div style="color: #b3b2c2; font-size: 16px; font-weight: bolder">
              <div
                class="title"
                style="color: white; font-size: 20px; margin-bottom: 5px"
              >
                {{ i.name }}
              </div>
              <div class="price">
                本菜金额：<span style="color: #bfbb71; margin-right: 50px"
                  >￥{{ i.price }}元 / 份</span
                >
              </div>
              <div class="spec" style="margin: 5px 0" v-if="i.yellow[0] != ''">
                <img
                  style="width: 20px; vertical-align: middle; margin-right: 5px"
                  src="../assets/image/huangdeng.png"
                  alt=""
                />黄灯食物：<span style="color: #bfbb71"
                  >{{ i.yellow[0] }}、{{ i.yellow[1] }}</span
                >
              </div>
              <div class="spec" style="margin: 5px 0" v-if="i.green[0] != ''">
                <img
                  style="width: 20px; vertical-align: middle; margin-right: 5px"
                  src="../assets/image/lvdeng.png"
                  alt=""
                />绿灯食物：<span style="color: #bfbb71"
                  >{{ i.green[0] }}、{{ i.green[1] }}</span
                >
              </div>
              <div class="spec" style="margin: 5px 0" v-if="i.red[0] != ''">
                <img
                  style="width: 20px; vertical-align: middle; margin-right: 5px"
                  src="../assets/image/hongdeng.png"
                  alt=""
                />红灯食物：<span style="color: #bfbb71"
                  >{{ i.red[0] }}、{{ i.red[1] }}</span
                >
              </div>
              <div class="calorie">
                <img
                  style="width: 20px; vertical-align: middle; margin-right: 5px"
                  src="../assets/image/jinzhi.png"
                  alt=""
                />禁用人群：<span style="color: #bfbb71">{{
                  i.specialCareful || "无"
                }}</span>
              </div>
            </div>
          </div>
          <div
            style="
              display: flex;
              margin-top: 15px;
              justify-content: space-around;
            "
          >
            <Chart
              style="width: 50%; height: 300px"
              :option="setTemperature5(i.food_info)"
            ></Chart>
            <div
              style="
                width: 32%;
                height: 240px;
                background-color: #2c605c;
                margin-top: 10px;
                margin-right: 15px;
                border-radius: 15px;
                color: white;
                padding: 20px;
              "
            >
              <div
                style="
                  font-size: 18px;
                  font-weight: bolder;
                  border-bottom: 2px solid white;
                  padding: 10px 0;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <span>营养素</span><span>每100克</span>
              </div>

              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  margin: 20px 0;
                "
              >
                <div>蛋白质(克)</div>
                <div>{{ i.food_detail.protein }}</div>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  margin: 20px 0;
                "
              >
                <div>碳水化合物(克)</div>
                <div>{{ i.food_detail.protein }}</div>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  margin: 20px 0;
                "
              >
                <div>脂肪(克)</div>
                <div>{{ i.food_detail.fat }}</div>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  margin: 20px 0;
                  border-bottom: 2px solid white;
                  padding-bottom: 35px;
                "
              >
                <div>能量(大卡)</div>
                <div>{{ i.food_detail.energy_kcal }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import Chart from "../components/chart.vue";
import { getFoodAnalysis } from "../api/api";
export default {
  data() {
    return {
      list: [],
      listContainer: [],
      food_info: [],
      ChartTemperature5: {}, //热量配置项
    };
  },
  components: {
    Chart,
  },
  methods: {
    setTemperature5(item) {
      return {
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
        },
        visualMap: {
          show: false,
          min: 500,
          max: 600,
          inRange: {
            //colorLightness: [0, 1]
          },
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: "50%",
            center: ["50%", "50%"],
            color: ["#1fc9fb","#fd5050","#1fc9fb", "#FE5050", ], //'#FBFE27','rgb(11,228,96)','#FE5050'
            data: item,
            roseType: "radius",

            label: {
              normal: {
                formatter: ["{c|{c}%}", "{b|{b}}"].join("\n"),
                rich: {
                  c: {
                    color: "rgb(241,246,104)",
                    fontSize: 20,
                    fontWeight: "bold",
                    lineHeight: 5,
                  },
                  b: {
                    color: "rgb(98,137,169)",
                    fontSize: 15,
                    height: 40,
                  },
                },
              },
            },
            labelLine: {
              normal: {
                lineStyle: {
                  color: "rgb(98,137,169)",
                },
                smooth: 0.2,
                length: 10,
                length2: 20,
              },
            },
            itemStyle: {
              normal: {
                shadowColor: "rgba(0, 0, 0, 0.8)",
                shadowBlur: 50,
              },
            },
          },
        ],
      };
    },

    async getgetFoodAnalysisData() {
      const res = await getFoodAnalysis();
      this.list = res.data.data.map((item) => {
        if (!item.food_images?.includes("http")) {
          return {
            ...item,
            food_images:
              "https://shuwangysa.oss-cn-hangzhou.aliyuncs.com" +
              item.food_images,
            food_info: item.food_info.map((foodItem) => ({
              ...foodItem,
              value: foodItem.percent,
            }))
          };
        } else {
          return {
            ...item,
            food_info: item.food_info.map((foodItem) => ({
              ...foodItem,
              value: foodItem.percent,
            })),
          };
        }
      });
      for (let i = 0; i < this.list.length; i += 4) {
        this.listContainer.push(this.list.slice(i, i + 4));
      }
      console.log(this.listContainer, "getFoodAnalysis");
    },
  },
  mounted() {
    this.getgetFoodAnalysisData();
  },
  watch: {},
};
</script>

<style scoped>
::v-deep .el-carousel__container {
  height: 950px;
}
</style>
