<template>
  <!-- 全屏容器 -->
  <v-scale-screen width="1920" height="1080" fullScreen>
    <div class="container">
      <dv-border-box-11 title="物联网硬件监控大屏">
        <div class="borderBox">
          <!-- 日期 -->
          <!-- <div
            class="dateTime"
            style="
              color: white;
              position: absolute;
              top: 80px;
              right: 150px;
              font-weight: bolder;
              font-size: 18px;
            "
          >
            {{ formattedDate }}
          </div> -->
          <!-- 返回 -->
          <div
            style="
              height: 8%;
              display: flex;
              align-items: flex-end;
              padding: 20px 155px 40px;
            "
          >
            <i
              class="el-icon-d-arrow-left"
              style="
                color: aliceblue;
                font-size: 20px;
                position: absolute;
                top: 80px;
                left: 35px;
              "
              @click="goback"
              >返回</i
            >
            <!-- 切换地点 -->
            <!-- <el-select
              v-model="addressValue"
              placeholder="粮油库"
              type="primary"
              @change="handleAddressChange"
            >
              <el-option
                v-for="item in addressOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
            <div class="warehouseCategory">
              <span class="warehouseSpan">地点：</span>
              <el-dropdown
                size="mini"
                split-button
                type="primary"
                trigger="click"
              >
                <span class="el-dropdown-link">
                  {{ selectValue }}
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item in options"
                    :key="item.value"
                    @click.native.stop="changeValue1(item.label)"
                  >
                    <span> {{ item.label }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <!-- 切换天数 -->
            <el-radio-group
              v-model="radio"
              style="margin-left: 30px; margin-bottom: 5px"
              @input="changeDays"
            >
              <el-radio :label="7" style="color: white">7天</el-radio>
              <el-radio :label="15" style="color: white">15天</el-radio>
              <el-radio :label="30" style="color: white">30天</el-radio>
            </el-radio-group>
          </div>
          <!-- 图表 -->
          <div
            id="box"
            style="height: 80%; width: 95%; padding: 20px; margin-left: 28px"
          >
            <!-- 温湿度 -->
            <div
              style="
                width: 100%;
                height: 48%;
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span style="position: absolute; top: 155px; left: 50px"
                >温度</span
              >
              <span style="position: absolute; top: 155px; left: 1000px"
                >湿度</span
              >
              <Chart
                width="80%"
                height="87%"
                :option="ChartTemperature1"
              ></Chart>
              <dv-decoration-4
                style="width: 5px; height: 250px; margin: 0 30px"
              />
              <Chart
                width="80%"
                height="87%"
                :option="ChartTemperature2"
              ></Chart>
            </div>
            <dv-decoration-4 :reverse="true" style="width: 100%; height: 5px" />
            <!-- 烟雾和水浸 -->
            <div
              style="
                width: 100%;
                height: 45%;
                display: flex;
                justify-content: space-evenly;
                margin-top: 50px;
              "
            >
              <dv-border-box-7 style="width: 65%">
                <div style="width: 100%; height: 100%">
                  <span
                    style="
                      margin-top: 10px;
                      margin-left: 10px;
                      display: inline-block;
                    "
                    >烟感</span
                  >
                  <Chart
                    width="100%"
                    height="100%"
                    :option="ChartSensing"
                  ></Chart>
                </div>
              </dv-border-box-7>
              <!-- 水浸 -->
              <div style="width: 30%; height: 90%; margin-left: 12px">
                <dv-border-box-13 style="padding: 20px 15px">
                  <span>水浸</span>
                  <el-table
                    :data="tableData"
                    ref="rolltable"
                    @mouseenter.native="stopScroll"
                    @mouseleave.native="startScroll"
                    height="325"
                    style="width: 100%; height: 100%"
                    :row-class-name="tableRowClassName"
                    :header-cell-style="{
                      color: '#fff',
                      background: '#0a3370',
                      fontWeight: '700',
                    }"
                  >
                    <el-table-column prop="day_time" label="日期" width="">
                    </el-table-column>
                    <el-table-column
                      prop="hour_time"
                      label="时间（时）"
                      width="220"
                    >
                    </el-table-column>
                    <el-table-column prop="name" label="地点" width="">
                    </el-table-column>
                    <el-table-column prop="water" label="是否有水">
                    </el-table-column>
                  </el-table>
                </dv-border-box-13>
              </div>
            </div>
          </div>
        </div>
      </dv-border-box-11>
    </div>
  </v-scale-screen>
</template>
<script>
import ChartBox from "../components/chartBox.vue";
import Chart from "../components/chart.vue";
import {
  getIotTempWetChats,
  getIotSmogChats,
  getIotWaterChats,
} from "../api/api";
import * as echarts from "echarts";
// 引入混入
import { myMixin } from "../common/common.js";

export default {
  data() {
    return {
      selectValue: "粮油库",
      options: [
        {
          label: "粮油库",
          value: "0",
        },
        {
          label: "干调库",
          value: "1",
        },
      ],
      radio: 7,
      locationIndex: 0,
      switchValue: true,
      switchValue2: true,
      axisData1: [], //日期
      seriesData: [
        [], //最高温度
        [], //最低温度
        [], //最高湿度
        [], //最低湿度
      ], //数据
      ChartTemperature1: {}, //图表配置项（温度）
      ChartTemperature2: {}, //图表配置项（温度）
      ChartSensing: {}, //图表配置项（火灾预警【烟雾】）
      list: [], //烟雾数据
      listTitle: "",
      list2Title: "",
      list2: [], //烟雾数据
      tableData: [],
      addressOptions: [
        {
          value: "0",
          label: "粮油库",
        },
        {
          value: "1",
          label: "干调库",
        },
      ],
      addressValue: "",
    };
  },
  mixins: [myMixin],
  methods: {
    changeValue1(e) {
      e == "粮油库" ? (this.locationIndex = 0) : (this.locationIndex = 1);
      this.getIotTempWetChatsData();
      this.getlotWaterChatsData();
      this.getTemperatures();
    },
    // 地点下拉框
    // handleAddressChange(value) {
    //   value == 0 ? (this.locationIndex = 0) : (this.locationIndex = 1);
    //   this.getIotTempWetChatsData();
    //   this.getlotWaterChatsData();
    //   this.getTemperatures();
    // },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    },
    // 改变天数
    changeDays: function (val) {
      this.getIotTempWetChatsData(val);
      this.getlotWaterChatsData(val);
      this.getTemperatures(val);
    },
    setTemperature() {
      this.ChartTemperature1 = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: "rgba(255,255,255,0.7)",
            },
          },
          backgroundColor: "rgba(255,255,255,0.1)",
          padding: [5, 10],
          textStyle: {
            color: "rgb(255,255,255)",
          },
        },
        grid: {
          left: "1%",
          right: "1%",
          top: "10%",
          bottom: "12%",
          containLabel: true,
        },
        legend: {
          x: "center",
          y: "0",
          icon: "circle",
          itemWidth: 12,
          itemHeight: 12,
          itemGap: 60,
          textStyle: {
            color: "#C0C4CC",
            fontSize: 13,
            fontWeight: "bold",
          },
          data: ["最高温度", "最低温度"],
        },
        xAxis: {
          type: "category",
          data: this.axisData1,
          boundaryGap: true,
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              type: "line",
              color: "#2557B4",
            },
          },
          axisLabel: {
            margin: 10,
            textStyle: {
              fontSize: 14,
              color: "#C0C4CC",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            position: "left",
            name: "单位：温度（℃）",
            nameTextStyle: {
              padding: [0, 0, 0, 65],
              color: "#C0C4CC",
              fontSize: 13,
              fontWeight: "bold",
              // lineHeight: 40,
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: "dashed",
                color: "#C0C4CC",
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "rgba(255,255,255,0.3)",
              },
            },
            axisLabel: {
              show: true,
              margin: 2,
              textStyle: {
                fontSize: 12,
                color: "#DEEBFF",
              },
            },
          },
        ],
        series: [
          {
            name: "最高温度",
            type: "line",
            smooth: true,
            showSymbol: true,
            symbol: "circle",
            symbolSize: 10,
            data: this.seriesData[0],
            itemStyle: {
              normal: {
                color: "#F4F65B",
              },
            },
            lineStyle: {
              normal: {
                width: 3,
              },
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "rgba(116,160,249,0.15)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(29,63,120,0)", // 100% 处的颜色
                  },
                ],
                false
              ),
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#F4F65B",
              },
            },
          },
          {
            name: "最低温度",
            type: "line",
            smooth: true,
            showSymbol: true,
            symbol: "circle",
            symbolSize: 10,
            data: this.seriesData[1],
            itemStyle: {
              normal: {
                color: "#55D1FD",
              },
            },
            lineStyle: {
              normal: {
                width: 3,
              },
            },
            label: {
              show: true,
              position: "bottom",
              textStyle: {
                color: "#55D1FD",
              },
            },
          },
        ],
      };
    },
    setTemperature2() {
      this.ChartTemperature2 = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: "rgba(255,255,255,0.7)",
            },
          },
          backgroundColor: "rgba(255,255,255,0.1)",
          padding: [5, 10],
          textStyle: {
            color: "rgb(255,255,255)",
          },
        },
        grid: {
          left: "1%",
          right: "1%",
          top: "10%",
          bottom: "12%",
          containLabel: true,
        },
        legend: {
          x: "center",
          y: "0",
          icon: "circle",
          itemWidth: 12,
          itemHeight: 12,
          itemGap: 60,
          textStyle: {
            color: "#C0C4CC",
            fontSize: 13,
            fontWeight: "bold",
          },
          data: ["最高湿度", "最低湿度"],
        },
        xAxis: {
          type: "category",
          data: this.axisData1,
          boundaryGap: true,
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          // type: 'dashed',
          axisLine: {
            lineStyle: {
              type: "line",
              color: "#2557B4",
            },
          },
          axisLabel: {
            margin: 10,
            textStyle: {
              fontSize: 14,
              color: "#C0C4CC",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            position: "left",
            name: "单位：湿度（ %rh）",
            nameTextStyle: {
              padding: [0, 0, 0, 80],
              color: "#C0C4CC",
              fontSize: 13,
              fontWeight: "bold",
              // lineHeight: 40,
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: "dashed",
                color: "#C0C4CC",
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "rgba(255,255,255,0.3)",
              },
            },
            axisLabel: {
              show: true,
              margin: 2,
              textStyle: {
                fontSize: 12,
                color: "#DEEBFF",
              },
            },
          },
        ],
        series: [
          {
            name: "最高湿度",
            type: "line",
            smooth: true,
            showSymbol: true,
            symbol: "circle",
            symbolSize: 10,
            data: this.seriesData[2],
            itemStyle: {
              normal: {
                color: "#287CE8",
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#287CE8",
              },
            },
            lineStyle: {
              normal: {
                width: 3,
              },
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "rgba(116,160,249,0.25)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(29,63,120,0)", // 100% 处的颜色
                  },
                ],
                false
              ),
            },
          },
          {
            name: "最低湿度",
            type: "line",
            smooth: true,
            showSymbol: true,
            symbol: "circle",
            symbolSize: 10,
            data: this.seriesData[3],
            itemStyle: {
              normal: {
                color: "#38FFBA",
              },
            },
            label: {
              show: true,
              position: "bottom",
              textStyle: {
                color: "#38FFBA",
              },
            },
            lineStyle: {
              normal: {
                width: 3,
              },
            },
          },
        ],
      };
    },
    // 烟雾
    setSensingChart() {
      this.ChartSensing = {
        legend: {
          show: true,
          textStyle: {
            color: "#fff", // white
            fontSize: 16,
          },
        },
        grid: {
          left: "12%",
          top: "25%",
          bottom: "30%",
          right: "10%",
        },
        xAxis: {
          name: "日期",
          nameTextStyle: {
            color: "#fff",
            padding: [0, 0, 0, 20],
          },
          show: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
              shadowColor: "rgba(117, 168, 202, 0.3)",
              shadowOffsetX: "20",
            },
            symbol: ["none", "arrow"],
            symbolOffset: [0, 25],
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: "rgba(117, 168, 202, 0.3)",
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: 14,
              fontWeight: 600,
              color: "#fff",
            },
            rotate: 45,
          },
          axisTick: {
            show: false,
          },
          data: this.list.map((val) => {
            return val.name;
          }),
        },
        yAxis: [
          {
            name: "烟感单位（PPM）",
            nameTextStyle: {
              color: "#fff",
              padding: [0, 0, 0, 120],
            },
            max: 2000,
            min: 0,
            splitNumber: (123 % 5).toFixed(0),
            show: true,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              symbol: ["none", "arrow"],
              symbolOffset: [0, 15],
              lineStyle: {
                // color: 'rgba(255, 129, 109, 0.1)',
                width: 1, //这里是为了突出显示加上的
                color: "#fff",
                shadowColor: "#fff",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                fontSize: 12,
                fontWeight: 600,
                color: "#fff",
              },
            },
            splitArea: {
              areaStyle: {
                color: "#fff",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(117, 168, 202, 0.3)",
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: this.listTitle,
            type: "pictorialBar",
            barCategoryGap: "-20%",
            /*多个并排柱子设置柱子之间的间距*/
            symbol:
              "path://M0,10 L10,10 C5.5,10 6.5,5 5,5 C3.5,5 4.5,10 0,10 z",
            label: {
              show: true,
              position: "top",
              distance: 10,
              color: "#fff",
              fontWeight: "bolder",
              fontSize: 12,
            },
            itemStyle: {
              normal: {
                color: (params) => {
                  const colorList = [
                    {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        { offset: 0, color: "#297ff2" },
                        { offset: 1, color: "#cce7fc" },
                      ],
                    },
                    {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        { offset: 0, color: "#3bfafe" },
                        { offset: 1, color: "#aaf4fe" },
                      ],
                    },
                    {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        { offset: 0, color: "#e08440" },
                        { offset: 1, color: "#decabd" },
                      ],
                    },
                  ];

                  // Duplicate colors to have a total of 10 colors
                  for (let i = colorList.length; i < 90; i++) {
                    colorList.push(colorList[i % colorList.length]);
                  }

                  return colorList[params.dataIndex];
                },
                opacity: 0.7,
              },
              // 鼠标移入柱子上 透明度变为 1
              emphasis: {
                opacity: 1,
              },
            },
            data: this.list.map((v) => {
              return v.value;
            }),
          },
          {
            name: this.list2Title,
            type: "pictorialBar",
            barCategoryGap: "-20%",
            /*多个并排柱子设置柱子之间的间距*/
            symbol:
              "path://M0,10 L10,10 C5.5,10 6.5,5 5,5 C3.5,5 4.5,10 0,10 z",
            label: {
              show: true,
              position: "top",
              distance: 10,
              color: "#fff",
              fontWeight: "bolder",
              fontSize: 12,
            },
            itemStyle: {
              normal: {
                color: (params) => {
                  const colorList = [
                    {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        { offset: 0, color: "#bfa" },
                        { offset: 1, color: "#c9f29b" },
                      ],
                    },
                    {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        { offset: 0, color: "#bfa" },
                        { offset: 1, color: "#c9f29b" },
                      ],
                    },
                    {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        { offset: 0, color: "#bfa" },
                        { offset: 1, color: "#c9f29b" },
                      ],
                    },
                  ];

                  // Duplicate colors to have a total of 10 colors
                  for (let i = colorList.length; i < 90; i++) {
                    colorList.push(colorList[i % colorList.length]);
                  }

                  return colorList[params.dataIndex];
                },
                opacity: 0.7,
              },
              // 鼠标移入柱子上 透明度变为 1
              emphasis: {
                opacity: 1,
              },
            },
            data: this.list2.map((v) => {
              return v.value;
            }),
          },
        ],
      };
    },
    // 获取getSmoke
    async getTemperatures(val) {
      let valueToUse = val !== undefined ? val : 7;
      const res = await getIotSmogChats({ days: valueToUse });
      this.list = res.data.data[0].data
        .slice(0, valueToUse * 3)
        .map((item, index) => ({
          name: item.hour_start
            .toString()
            .substring(item.hour_start.length - 8), // 将hour_start的值作为name
          value: item.max_ppm, // 将max_ppm的值作为value
        }));
      this.listTitle = res.data.data[0].name;

      if (res.data.data.length > 1) {
        this.list2 = res.data?.data[1]?.data
          .slice(0, valueToUse * 3)
          ?.map((item, index) => ({
            name: item.hour_start
              .toString()
              .substring(item.hour_start.length - 8), // 将hour_start的值作为name
            value: item.max_ppm, // 将max_ppm的值作为value
          }));
          this.list2Title = res.data.data[1].name;
      }
      console.log(this.list, this.list2, "getSmoke");

      this.setSensingChart();
    },
    // 温湿度
    async getIotTempWetChatsData(val) {
      this.axisData1 = [];
      this.seriesData[0] = [];
      this.seriesData[1] = [];
      this.seriesData[2] = [];
      this.seriesData[3] = [];
      const res = await getIotTempWetChats({ days: val });
      // 假设res.data.data[0].data是一个对象数组
      let dataArray = res.data.data[this.locationIndex].data;
      // 使用map方法遍历对象数组，同时处理date、max_temp、min_temp、max_wet和min_wet字段的值
      dataArray.forEach((obj) => {
        // 日期
        this.axisData1.push(obj.date.substring(obj.date.length - 5));
        // 最高温度
        this.seriesData[0].push(obj.max_temp);
        //最低温度
        this.seriesData[1].push(obj.min_temp);
        // 最高湿度
        this.seriesData[2].push(obj.max_wet);
        // 最低湿度
        this.seriesData[3].push(obj.min_wet);
      });
      this.setTemperature();
      this.setTemperature2();
    },
    // 水浸getlotWaterChats
    async getlotWaterChatsData(val) {
      const res = await getIotWaterChats({ days: val });
      // 遍历res.data.data[0].data数组
      res.data.data[0].data.forEach((item) => {
        // 为每个对象添加name键值对
        item.name = res.data.data[0].name;
        // 将对象推送到tableData数组中
        this.tableData.push(item);
      });
    },
  },
  components: { ChartBox, Chart },
  created() {
    this.getIotTempWetChatsData();
    this.getTemperatures(7);
    this.getlotWaterChatsData();
  },
  mounted() {
    // this.tableScroll(false);
  },
  beforeDestroy() {
    clearInterval(this.rolltimer);
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 1920px;
  height: 1080px;
  background: url("../assets/image/bg.577ccc4c.jpg");
  .borderBox {
    height: 100%;
    width: 100%;
  }
  .imgwrap {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 9999999;
  }
  @keyframes animated-border {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
  }
  #box {
    animation: animated-border 1.5s infinite;
    font-family: Arial;
    font-size: 18px;
    line-height: 30px;
    font-weight: bold;
    color: white;
    border: 2px solid;
    border-radius: 10px;
    padding: 15px;
  }
}
.warehouseCategory {
  width: 180px;
  .warehouseSpan {
    color: white;
    font-size: 16px;
    display: inline-block;
    width: 50px;
  }
}
::v-deep .el-table .warning-row {
  background: oldlace;
}

::v-deep .el-table .success-row {
  background: #f0f9eb;
}

// 表格内背景颜色
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  background-color: #063570; // 背景透明
  background-color: rgb(2, 19, 49); // 背景透明
  border: 0px;
  color: white; // 修改字体颜色
  font-size: 14px;
  text-align: center;
  height: 5px;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: Normal;
}
// 去掉最下面的那一条线
::v-deep .el-table::before {
  height: 0px;
}
// 修改表头样式-加边框
::v-deep .el-table__header-wrapper {
  border: solid 2px #063570;
  box-sizing: border-box;
}

// 修改高亮当前行颜色
::v-deep .el-table tbody tr:hover > td {
  background: #063570 !important;
}
// 滚动条样式
::v-deep .container .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px !important;
}

::v-deep .container .el-table__body-wrapper::-webkit-scrollbar-track-piece {
  background: rgb(1, 22, 55) !important;
}

::v-deep .container .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background: #99a9bf;
  border-radius: 20px;
}
::v-deep .el-table {
  th.gutter,
  colgroup.gutter {
    width: 4px !important; //此处的宽度值，对应你自定义滚动条的宽度即可
  }
}
// 关键css代码
::v-deep .el-table__header colgroup col[name="gutter"] {
  display: table-cell !important;
}
::v-deep .el-table__body {
  width: 101% !important;
}
// 下拉框的样式
::v-deep .el-input__inner {
  height: 30px;
  line-height: 30px;
  width: 100px;
}
::v-deep .el-input__icon {
  line-height: 30px;
}
</style>
